<template>
    <div class="follow-list">
        <div
            v-if="$slots.title"
            class="follow-list__title"
        >
            <slot name="title" />
        </div>
        <ButtonList>
            <li
                v-for="dossier in shownDossiers"
                :key="dossier.id"
            >
                <DossierFollowButton
                    :size="buttonSize ? buttonSize : 'small'"
                    :dossier-id="dossier.id"
                />
            </li>
            <li>
                <BaseButton
                    :size="buttonSize ? buttonSize : 'small'"
                    :class="moreButtonClass"
                    icon="arrow-right"
                    @click="modalStore.open('follow-dossiers')"
                >
                    Alle thema's en dossiers
                </BaseButton>
            </li>
        </ButtonList>
    </div>
</template>

<script setup>
import { useDossierStore } from '~/store/dossiers';
import { useModalStore } from '~/store/modal';
import { useAuthStore } from '~/store/auth';

const props = defineProps({
    dossiers: {
        type: Array,
        default: () => []
    },
    buttonSize: {
        type: String,
        default: 'medium',
        validator(value) {
            return ['medium', 'small', 'large'].includes(value);
        }
    },
    moreButtonClass: {
        type: String,
        default: 'base-button--quaternary'
    },
    hideFollowedDossiers: {
        type: Boolean,
        default: false
    }
});

const modalStore = useModalStore();
const dossierStore = useDossierStore();
const user = computed(() => {
    return useAuthStore().userModel;
});

const followedIds = toValue(toValue(user).followingDossierIds) || [];

const followedDossiers = computed(() => {
    const ids = followedIds;

    return dossierStore.dossiers.filter(dossier => {
        return ids.includes(parseInt(dossier.id, 10));
    });
});

const shownDossiers = computed(() => {
    // Filter out dossiers that are already followed
    const filteredDossiersProp = props.dossiers.filter(dossier => {
        return !followedIds.includes(parseInt(dossier.id, 10));
    });

    if (props.hideFollowedDossiers) {
        return [...filteredDossiersProp];
    }

    return [...filteredDossiersProp, ...toValue(followedDossiers)];
});
</script>

<style lang="less" src="./DossierFollowList.less"></style>
